import { defineComponent, ref, reactive, watch, toRefs, computed } from 'vue';
export default defineComponent({
  name: 'basicTemplate',
  props: {
    tableData: {
      type: Array,
      default: []
    },
    tableColumns: {
      type: Array,
      default: []
    },
    tableSize: {
      type: String,
      default: 'small'
    },
    total: {
      type: Number,
      default: 0
    },
    jumpKey: {
      type: Number,
      default: null
    },
    pageSize: {
      type: Number,
      default: 100
    },
    current: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    filterStyle: {
      type: String,
      default: ''
    }
  },

  setup(props, {
    emit
  }) {
    const tableBox = ref(null);
    const tableRef = ref(null);
    const pagination = reactive({
      total: computed(() => props.total),
      current: props.current,
      pageSize: props.pageSize
    });
    watch(() => props.current, value => {
      // console.log(value, 'value');
      pagination.current = value;
    });

    const pageSizeChange = (current, pageSize) => {
      let table = document.getElementsByClassName('el-table__body')[0];
      table.scrollIntoView();
      emit('size-change', pageSize);
    };

    const currentChange = (current, pageSize) => {
      try {
        let table = document.getElementsByClassName('el-table__body')[0]; // table.scrollIntoView();

        emit('current-change', current);
      } catch (error) {
        console.log(error, 'error');
      }
    }; // 高亮搜索的行


    const tableRowClassName = e => {
      if (props.jumpKey === null) return;

      if (e.rowIndex === props.jumpKey) {
        return 'warning-row';
      }
    }; // 多选


    const getSelection = val => {
      let rows = tableRef.value.getSelectionRows();
      emit('selectionChange', rows);
      return rows;
    };

    return {
      tableRowClassName,
      tableBox,
      ...toRefs(pagination),
      pageSizeChange,
      currentChange,
      getSelection,
      tableRef
    };
  }

});