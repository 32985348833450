import { defineComponent, toRefs, watch, reactive } from 'vue';
export default defineComponent({
  props: {
    // 侧边栏数据
    list: {
      type: Array,
      default: []
    },
    // 侧边栏展开项
    openOptions: {
      type: Array,
      default: []
    },
    // 默认显示项路由name
    defaultPage: {
      type: String,
      default: ""
    },
    // 路由记录历史模式标识, 开启后会记住当前页最后一次选中的二级路由, 刷新页面后重置。
    histroyModel: {
      type: Boolean,
      default: false
    }
  },

  setup(props, {
    emit
  }) {
    const handleOpen = (key, keyPath) => {
      console.log(key, keyPath);
    };

    const handleClose = (key, keyPath) => {
      console.log(key, keyPath);
    };

    function setRoute(item) {
      // props.histroyModel &&
      //   localStorage.setItem(
      //     [rootRoute.value, "_histroyRoute"].join(""),
      //     nowRoute
      //   );
      // 路由跳转
      emit("change", item);
    }

    const refData = reactive({
      activePage: props.defaultPage
    });
    watch(() => props.defaultPage, value => {
      console.log(value, 'valueWatch');
      refData.activePage = value;
    }); // // 获取当前路由根路径
    // const rootRoute = computed(() => {
    //   return router.currentRoute.value.fullPath.split("/")[1];
    // });

    return {
      setRoute,
      ...toRefs(props),
      ...toRefs(refData),
      handleOpen,
      handleClose
    };
  }

});