import { computed, defineComponent, nextTick, reactive, toRefs, watch } from "vue";
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "800px"
    },
    DialogType: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    titleTip: {
      type: String,
      default: ""
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    showCheck: {
      type: Boolean,
      default: false
    },
    isCheck: {
      type: Boolean,
      default: false
    },
    checkTitle: {
      type: String,
      default: "对该资产组下的所有资产进行设置"
    },
    dialogTop: {
      type: String,
      default: "15vh"
    },
    dialogClass: {
      type: String,
      default: "eldialog_class"
    }
  },
  emits: ["confirmOk", "update:visible", "clearDialogType", "update:isCheck", "closed"],

  setup(props, {
    emit
  }) {
    const {
      DialogType,
      isCheck
    } = toRefs(props);
    const data = reactive({
      others: {},
      checked: false,
      closed: function () {
        emit("clearDialogType");
        emit("update:visible", false);
        emit("closed");
      },
      opened: function () {}
    });
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit("update:visible", val);
      }
    });

    function confirm() {
      emit("confirmOk", DialogType.value, data.others);
    }

    nextTick(() => {
      data.checked = isCheck.value;
      setTimeout(() => {
        watch(() => data.checked, val => {
          emit("update:isCheck", val);
        });
      }, 100);
    });
    return {
      confirm,
      show,
      ...toRefs(props),
      ...toRefs(data)
    };
  }

});