import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2e219c65"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "home"
};
var _hoisted_2 = {
  class: "home__header"
};
var _hoisted_3 = {
  class: "home__content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Info = _resolveComponent("Info");

  var _component_DeviceList = _resolveComponent("DeviceList");

  var _component_Architecture = _resolveComponent("Architecture");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_Info, {
    ref: "refInfo"
  }, null, 512)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_DeviceList, {
    ref: "refDeviceList",
    class: "home__content__left",
    onRefresh: $setup.Refresh
  }, null, 8, ["onRefresh"]), _createVNode(_component_Architecture, {
    ref: "refArchitecture",
    class: "home__content__right",
    onRefresh: $setup.Refresh
  }, null, 8, ["onRefresh"])])]);
}