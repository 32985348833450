function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import Architecture from './components/architecture.vue';
import DeviceList from './components/deviceList.vue';
import Info from './components/info.vue';
import { reactive, toRefs, onMounted, onUnmounted, nextTick } from 'vue';
export default {
  name: 'home',
  components: {
    Architecture: Architecture,
    DeviceList: DeviceList,
    Info: Info
  },
  setup: function setup() {
    var refData = reactive({
      setHomeTime: null,
      refInfo: '',
      refDeviceList: '',
      refArchitecture: ''
    });
    onMounted(function () {
      // 在这里开始首页的轮询获取数据，当前 5分钟轮询一次
      nextTick(function () {
        refData.setHomeTime = setInterval(function () {
          if (refData.refInfo) refData.refInfo.getStatisticalNumber();

          if (refData.refDeviceList) {
            refData.refDeviceList.getDataAll(1, 50, false);
          }

          if (refData.refArchitecture) {
            refData.refArchitecture.refresh = !refData.refArchitecture.refresh;
          }
        }, 5 * 60 * 1000);
      });
    });
    onUnmounted(function () {
      // 在这里结束首页的轮询获取数据
      clearTimeout(refData.setHomeTime);
      refData.setHomeTime = '';
    });

    var Refresh = function Refresh() {
      if (refData.refInfo) refData.refInfo.getStatisticalNumber();
    };

    return _objectSpread({
      Refresh: Refresh
    }, toRefs(refData));
  }
};