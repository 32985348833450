import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-47f94f21"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "basicTemplate"
};
const _hoisted_2 = {
  class: "basicTemplate__table",
  ref: "tableBox",
  "element-loading-text": "拼命加载中"
};
const _hoisted_3 = {
  class: "basicTemplate__pager"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");

  const _component_el_table = _resolveComponent("el-table");

  const _component_el_pagination = _resolveComponent("el-pagination");

  const _directive_loading = _resolveDirective("loading");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.showFilter ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "basicTemplate__filter",
    style: _normalizeStyle(_ctx.filterStyle)
  }, [_renderSlot(_ctx.$slots, "filter", {}, undefined, true)], 4)) : _createCommentVNode("", true), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_table, {
    data: _ctx.tableData,
    size: _ctx.tableSize,
    class: "tableBox",
    height: _ctx.tableBox && _ctx.tableBox.offsetHeight - 10,
    "row-class-name": e => _ctx.tableRowClassName(e),
    ref: "tableRef",
    onSelectionChange: _ctx.getSelection
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (item, index) => {
      return _openBlock(), _createElementBlock(_Fragment, {
        key: index
      }, [item.fixed && item.visible && item.width ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: 0,
        label: item.title,
        prop: item.key,
        fixed: item.fixed,
        "header-align": item.align,
        align: item.align,
        "min-width": item.width
      }, _createSlots({
        _: 2
      }, [item.slots ? {
        name: "default",
        fn: _withCtx(data => [_renderSlot(_ctx.$slots, item.slots.customRender, _normalizeProps(_guardReactiveProps(data)), undefined, true)])
      } : undefined]), 1032, ["label", "prop", "fixed", "header-align", "align", "min-width"])) : item.visible && item.width ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: 1,
        type: item.type || '',
        label: item.title,
        prop: item.key,
        "header-align": item.align,
        align: item.align,
        "min-width": item.width
      }, _createSlots({
        _: 2
      }, [item.slots ? {
        name: "default",
        fn: _withCtx(data => [_renderSlot(_ctx.$slots, item.slots.customRender, _normalizeProps(_guardReactiveProps(data)), undefined, true)])
      } : undefined]), 1032, ["type", "label", "prop", "header-align", "align", "min-width"])) : item.visible && item.minwidth ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: 2,
        label: item.title,
        prop: item.key,
        "header-align": item.align,
        align: item.align,
        "min-width": item.minwidth
      }, _createSlots({
        _: 2
      }, [item.slots ? {
        name: "default",
        fn: _withCtx(data => [_renderSlot(_ctx.$slots, item.slots.customRender, _normalizeProps(_guardReactiveProps(data)), undefined, true)])
      } : undefined]), 1032, ["label", "prop", "header-align", "align", "min-width"])) : item.visible ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: 3,
        label: item.title,
        prop: item.key,
        "header-align": item.align,
        align: item.align
      }, _createSlots({
        _: 2
      }, [item.slots ? {
        name: "default",
        fn: _withCtx(data => [_renderSlot(_ctx.$slots, item.slots.customRender, _normalizeProps(_guardReactiveProps(data)), undefined, true)])
      } : undefined]), 1032, ["label", "prop", "header-align", "align"])) : _createCommentVNode("", true)], 64);
    }), 128))]),
    _: 3
  }, 8, ["data", "size", "height", "row-class-name", "onSelectionChange"])])), [[_directive_loading, _ctx.loading]]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_pagination, {
    "page-sizes": [20, 50, 100, 200],
    total: _ctx.total,
    "page-size": _ctx.pageSize,
    "onUpdate:page-size": _cache[0] || (_cache[0] = $event => _ctx.pageSize = $event),
    currentPage: _ctx.current,
    "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => _ctx.current = $event),
    onCurrentChange: _ctx.currentChange,
    onSizeChange: _ctx.pageSizeChange,
    background: "",
    layout: "total,prev, pager, next, jumper"
  }, null, 8, ["total", "page-size", "currentPage", "onCurrentChange", "onSizeChange"])])]);
}