import { defineComponent, toRefs, reactive, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElTag } from "element-plus";
export default defineComponent({
  components: {
    ElTag
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const {
      state,
      commit
    } = useStore();
    const refData = reactive({}); //当前浏览过的页面

    const tabsData = computed(() => state.pageCaches.pageCaches); //当前路径

    const activeUrl = computed(() => route.path); //跳转

    const handleLink = (url, title) => {
      document.title = title;
      url && url != activeUrl.value && router.push(url);
    }; //删除


    const handleRemove = name => {
      commit("delPageCache", name);
    };

    return { ...toRefs(refData),
      handleRemove,
      activeUrl,
      handleLink,
      tabsData
    };
  }

});